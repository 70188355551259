import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import FluidImage from '../components/fluid-image'

const Row1 = styled.div`
  max-width: 80rem;
  width: 100%;
  margin: 0rem auto;
  padding: 1.5rem 24px 0 24px;

  @media (max-width: 768px) {
    margin: 0rem auto 3rem;
    padding: 0;
  }
`

const HR = styled.hr`
margin: 1.5rem 0;
    border-top: 3px solid #fff;
`

const PA = styled.p`
  color: #10435e !important;
  font-size: 1.35rem;
  margin-bottom: 1.5rem;
`

const DIV = styled.div`
// background:black;
div {
max-width: 100% !important;
line-height: 1.55em;
margin-bottom: 1rem;
}
`

const H1 = styled.h1`
  margin: 0;
  color: #fff;
`


const A = styled.a`
  color: #000 !important;
  background: #fff !important;
  margin: .75rem 0 !important;
  padding: 10px 20px !important;
text-decoration: none !important;
`

const H2 = styled.h2`
  margin: 1rem 0;
  font-size: 3rem;
  font-weight: 700 !important;
`

const H3 = styled.h3`
  margin: 1rem 0 0.5rem;
`

const H4 = styled.h4`
  margin: 0;
`

const PageHeader = styled.div`
  /* background: red; */
  margin: 0 -24px;
`
const PageSection = styled.div`
  padding: 1rem 0;
  // background: #efefef;
  margin: 0 auto;
  max-width: 55rem;
  @media (max-width: 767px) {
    padding: 0 20px;
  }
`
const Framed = styled.div`
  max-width: 80rem;
  width: 100%;
  padding: 35px 24px 25px 24px;
  margin: auto;
  color: white;
  background: #0094d9;
`

const PageTitleSection = styled.div`
  background: #0094d9;
  padding: 20px 24px 10px;
  margin: 0 -25px -17px;
  @media (max-width: 767px) {
    padding: 20px 24px 10px;
    display: flex;
    background: #0094d9;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    height: 100%;
    margin: 15px -12px 0;
  }
`

const P = styled.p`
  color: #484848;
  font-size: 1.35rem;
`
const StyledSection = styled.div`
  margin: 0 -25px 40px -25px;
  border-radius: 3px;
  overflow: hidden;

  @media (max-width: 768px) {
    margin: -17px -12px 30px -12px;
  }
`

export default ({ data }) => {
  const {
    resources: { Name, URL, Description, slug, html, Level },
  } = data
  return (
    <Layout>
      <SEO title={Name} />

      <Row1>
        <PageTitleSection>
          <H1>{Name} </H1>
        </PageTitleSection>

        {/*<StyledSection>*/}
        {/*  <FluidImage image={featuredImage} style={{ marginBottom: '15px' }} />*/}
        {/*</StyledSection>*/}

        <PageSection>
          {/*<h1> {title} </h1>*/}
          <PA>{Description}</PA>

          <HR />
          <DIV dangerouslySetInnerHTML={{ __html: html }}  />

          <A className="relink" href={URL}>Learn More</A>
        </PageSection>
      </Row1>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    resources(slug: { eq: $slug }) {
      id
      slug
      Description
      Name
      URL
      Level
      html
    }
  }
`